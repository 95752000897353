<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Pricing band portal</h1>
      <div class=" flex justify-end items-end">
        <button
          class=" bg-blue-600 py-3 px-6 rounded-md  text-white"
          @click="
            $router.push({
              name: 'PricingBandAssignPortal',
            })
          "
        >
          Assign pricing band
        </button>
      </div>
      <!-- <div class="flex items-center mt-10">
        <input
          type="text"
          class="inline-block  px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search Pricing band"
          id=""
          v-model="search"
        />
        <button
          class="bg-blue-600 py-3 mx-5 px-6 rounded-md text-xs text-white"
          @click="searchRecords"
        >
          Search Records
        </button>
      </div> -->
      <div class="mt-14">
        <div class="mt-5" style="max-height: 600px; overflow: auto; ">
          <table class="shadow-lg bg-white w-full relative border-collapse">
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="32"
                style="background: #DEE6F2;"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Pricing ID
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Pricing band name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Loan Fee Percent
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                POS fee type
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                POS fee %
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                POS flat fee
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                POS fee cap
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Flat fee type conditions
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Providus fee type
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Providus fee %
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Providus flat fee
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Providus fee cap
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Flat fee type conditions
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Wema fee type
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Wema flat fee
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Wema fee%
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Wema fee cap
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Wema flat fee type conditions
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Accelerex fee type
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Accelerex fee %
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Accelerex flat fee
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Accelerex fee cap
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Flat fee type conditions
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Paystack fee type
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Paystack flat fee
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Paystack fee%
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Paystack fee cap
              </th>
            </tr>
            <tr class="text-xs" v-for="(account, i) in filterAccounts" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account._id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.transaction_fees_group_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.loan_fee_percent || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[0].trxn_fee_name === "POS" &&
                    account.transaction_fees_data[0].fee_type) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[0].trxn_fee_name === "POS" &&
                    account.transaction_fees_data[0].trxn_fee_percent) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[0].trxn_fee_name === "POS" &&
                    account.transaction_fees_data[0]
                      .trxn_fee_threshold_for_flat_fee) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[0].trxn_fee_name === "POS" &&
                    account.transaction_fees_data[0].cap_fee_ngn) ||
                    "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                v-if="account.transaction_fees_data[0].trxn_fee_name === 'POS'"
              >
                <ul
                  v-for="(condition, i) in account.transaction_fees_data[0]
                    .flat_fee_type_conditions"
                  :key="i"
                >
                  <li>
                    {{ condition.flat_fee_condition }}
                  </li>
                  <li>
                    {{ condition.flat_fee_amount }}
                  </li>
                </ul>
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs" v-else>
                N/A
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[1].trxn_fee_name ===
                    "Providus" &&
                    account.transaction_fees_data[1].fee_type) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[1].trxn_fee_name ===
                    "Providus" &&
                    account.transaction_fees_data[1].trxn_fee_percent) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[1].trxn_fee_name ===
                    "Providus" &&
                    account.transaction_fees_data[1]
                      .trxn_fee_threshold_for_flat_fee) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[1].trxn_fee_name ===
                    "Providus" &&
                    account.transaction_fees_data[1].cap_fee_ngn) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <ul
                  v-for="(condition, i) in account.transaction_fees_data[1]
                    .flat_fee_type_conditions"
                  :key="i"
                >
                  <li>
                    {{ condition.flat_fee_condition }}
                  </li>
                  <li>
                    {{ condition.flat_fee_amount }}
                  </li>
                </ul>
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[2].trxn_fee_name ===
                    "wema fee" &&
                    account.transaction_fees_data[2].fee_type) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[2].trxn_fee_name ===
                    "wema fee" &&
                    account.transaction_fees_data[2].trxn_fee_percent) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[2].trxn_fee_name ===
                    "wema fee" &&
                    account.transaction_fees_data[2]
                      .trxn_fee_threshold_for_flat_fee) ||
                    "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  (account.transaction_fees_data[2].trxn_fee_name ===
                    "wema fee" &&
                    account.transaction_fees_data[2].cap_fee_ngn) ||
                    "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                v-if="
                  account.transaction_fees_data[2].trxn_fee_name === 'wema fee'
                "
              >
                <ul
                  v-for="(condition, i) in account.transaction_fees_data[2]
                    .flat_fee_type_conditions"
                  :key="i"
                >
                  <li>
                    {{ condition.flat_fee_condition }}
                  </li>
                  <li>
                    {{ condition.flat_fee_amount }}
                  </li>
                </ul>
              </td>
              <td v-else>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>
          </table>
        </div>
        <!-- <div class="mt-3">
          <button
            @click="prevPage(currentPage)"
            :disabled="currentPage === 1 ? isDisabled : !isDisabled"
            class="
                      
                      uppercase
                      shadow
                      ml-3
                      hover:bg-blue-600
                      
                      focus:shadow-outline
                      focus:outline-none
                      text-xs
                      font-bold
                      py-3
                      px-8
                      rounded
                    "
            :class="
              currentPage === 1
                ? 'bg-blue-50 text-black'
                : 'bg-blue-900 text-white'
            "
          >
            &laquo; Prev
          </button>

          <button
            @click="nextPage(currentPage)"
            class="
                      float-right
                      uppercase
                      shadow
                      ml-3
                      hover:bg-blue-600
                      bg-blue-900
                      focus:shadow-outline
                      focus:outline-none
                      text-white text-xs
                      font-bold
                      py-3
                      px-8
                      rounded
                    "
            v-if="filterAccounts.length > 99"
          >
            Next &raquo;
          </button>
        </div> -->
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";
import authorizedUsers from "../config/authUsers";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      selected: [],
      selectedWhitelist: [],
      currentPage: 1,
      isDisabled: true,
      dateData: null,
      tableHeaders: [],
    };
  },
  computed: {
    ...mapGetters(["GET_PRICING_BAND_LISTS"]),
    filterAccounts() {
      return this.GET_PRICING_BAND_LISTS.data.items;
      //let y = this.GET_DOCUMENTS.data.filter((b) => b.status === x);
      //   return this.GET_PRICING_BAND_LISTS.data.items.fixAccount.filter(
      //     (account) =>
      //       account.custom_account_number.includes(this.search) ||
      //       account.custom_account_name
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase())
      //   );
      // this.documents = filtered;
    },
    check_auth() {
      const userEmail = GET_USER_BUSINESS_DATA().email;
      return authorizedUsers.includes(userEmail);
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {},
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchRecords();
  },
  methods: {
    async fetchRecords() {
      this.isLoading = true;
      let response = await this.$store.dispatch("FETCH_PRICING_BAND_LISTS");
      this.isLoading = false;
      if (response.data) {
      }
    },

    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "PAGINATE_PRICING_BAND_LIST",
        currPage - 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = { page: currPage + 1 };
      let res = await this.$store.dispatch(
        "PAGINATE_PRICING_BAND_LIST",
        currPage + 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
    async searchRecords() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "SEARCH_PRICING_BAND",
          this.search
        );

        this.isLoading = false;
        if (res.status) {
          this.isLoading = false;
          if (this.GET_FIXED_ACCOUNT_RES.data.items.fixAccount === null) {
            console.log("Testing again");
            alert("Couldn't find " + this.search);
            this.fetchRecords();
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
